<template>
  <div id="principal" v-if="telaPronta">
    <!-- Slot com informações dos filtros aplicados -->
    <div class="shadow-sm shadow-hover mb-1 card-header-filter">
      <div class="d-flex flex-column">
        <div class="mr-auto mt-1 px-2 fz-80 w-100">
          <div class="d-flex">
            <div class="text-right d-flex flex-column mr-1">
              <p class="d-flex align-items-center text-left m-0">
                <small class="label-orange label-bold mr-1">Nome: </small>
                <span class="text-muted label-bold"> {{ secaoSelecionada.Descricao }} </span>
              </p>
              <p class="d-flex align-items-center text-left m-0" v-if="secaoSelecionada.LocalizacaoInicial && secaoSelecionada.LocalizacaoFinal">
                <small class="label-orange mr-1">Range: </small>
                <span class="text-muted fz-80">
                  {{ obterRangeLocalizacao(secaoSelecionada) }}
                </span>
              </p>
              <p class="d-flex align-items-center text-left m-0" v-if="secaoSelecionada.Etapa">
                <small class="label-orange mr-1">Etapa: </small>
                <span class="text-muted fz-80"> {{ secaoSelecionada.Etapa }}ª conferência </span>
              </p>
            </div>
          </div>
        </div>
        <!--Bipar Localização-->
        <div class="px-2 mt-1" v-if="dadosExecucaoSecaoBalanco.ControlaLocalizacao">
          <input-pesquisa Id="inputPesquisaLocalizacao" ref="inputPesquisaLocalizacao" v-model="siglaLocalizacao" placeholder="Bipar Localização" @input="biparLocalizacao($event)"></input-pesquisa>
        </div>

        <!-- Bipar produtos -->
        <div class="d-flex justify-content-between align-items-center px-2 my-2">
          <div class="mr-1">
            <vue-numeric-input :disabled="requerLocalizacao" v-model="produto.Quantidade" type="tel" align="center" :min="0" size="90px" :value="1"></vue-numeric-input>
          </div>
          <div class="ml-1">
            <input-pesquisa
              :disabled="requerLocalizacao"
              Id="inputPesquisaProduto"
              ref="inputPesquisaProduto"
              v-focus
              v-model="produto.CodeBar"
              @input="buscarItem($event)"
              placeholder="Bipar Produto"
            ></input-pesquisa>
          </div>
        </div>
        <div class="text-right mr-2 mb-1 fz-70 text-muted">
          Quantidade de Itens bipados:<strong> {{ listaItensConferidos.length }}</strong>
        </div>
      </div>
    </div>

    <!--Grid de produtos bipados-->
    <div class="mx-2 tamanho-card-mobile fz-80">
      <div class="shadow-sm card my-2" v-for="(produto, index) in listaItensConferidos" :key="index">
        <div class="card-header bg-primary text-white size-font-label m-0 py-0 pl-2">
          {{ produto.Nome }}
        </div>
        <div class="card-body m-0 p-0">
          <div class="d-flex m-1">
            <div class="d-flex w-100">
              <div class="text-right d-flex flex-column justify-content-end align-items-end mr-1">
                <span class="label-orange">Cód. Produto:</span>
                <span class="label-orange">Referência:</span>
              </div>
              <div class="text-right d-flex flex-column align-items-start">
                <span class="text-muted size-font-label">
                  {{ produto.CodigoProduto }}
                </span>
                <span class="text-muted size-font-label">
                  {{ produto.Referencia }}
                </span>
              </div>
            </div>
            <div class="flex-shrink-1">
              <button class="btn btn-danger m-0 py-1 px-2" @click="onExcluirProduto(produto)">
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
          <!-- Localização-->
          <div class="d-flex justify-content-around m-1">
            <div class="d-flex flex-column align-items-center" v-if="produto.SiglaLocalizacao">
              <span class="label-orange">Localização</span>
              <span class="text-muted size-font-label"> {{ produto.SiglaLocalizacao }}</span>
            </div>
            <div class="d-flex flex-column align-items-center">
              <span class="label-orange">Qtde.</span>
              <span class="text-muted size-font-label">{{ produto.Quantidade }}</span>
            </div>
          </div>

          <div class="text-right mr-2 my-0 p-0 fz-70 text-muted">{{ index + 1 }} / {{ listaItensConferidos.length }}</div>
        </div>
      </div>
    </div>

    <div class="fixed-bottom bg-white mb-0">
      <div class="btn-group w-100 flex justify-content-end">
        <button :class="['btn btn-default ', isMobile ? 'btn-block mx-0 my-1' : 'm-1']" @click="redirecionarPagina">
          <i class="fas fa-arrow-left fa-lg pl-2"></i>
          Voltar
        </button>
        <button :disabled="!listaItensConferidos.length" :class="['btn btn-secondary', isMobile ? 'btn-block mx-0 my-1' : 'm-1']" @click="finalizarExecucaoSecao()">
          Finalizar
          <i class="fas fa-play fa-lg pl-2"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import loading from '@/common/loading';
import swal from '@/common/alerts';
import InputPesquisa from '../../components/input-pesquisa.vue';

export default {
  name: 'execucao-secao',
  components: { InputPesquisa },
  data() {
    return {
      telaPronta: false,
      errosValidacao: {},
      idSecao: '',
      nomeSecao: '',
      idBalanco: '',
      etapa: '',
      token: '',
      urlApi: '',
      siglaLocalizacao: '',
      produto: {
        NomeProduto: '',
        Quantidade: 1,
        CodeBar: '',
        CodigoProduto: null,
        Referencia: '',
      },
      ativo: false,
      leitorCodigoBarras: {
        ativo: false,
        retorno: null,
      },
      dadosExecucaoSecaoBalanco: {
        PermiteFinalizar: false,
        ControlaLocalizacao: false,
        Itens: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      isMobile: 'appData/ehWMSMobile',
      portaisUsuario: 'auth/portaisUsuario',
      empresasUsuario: 'auth/empresasUsuario',
      balancoSelecionado: 'balanco/balancoSelecionado',
      secaoSelecionada: 'balanco/secaoSelecionada',
      listaItensConferidos: 'balanco/listaItensConferidos',
      produtoPesquisado: 'balanco/produtoPesquisado',
    }),
    requerLocalizacao() {
      return this.dadosExecucaoSecaoBalanco.ControlaLocalizacao && !this.siglaLocalizacao.length;
    },
  },
  mounted() {
    this.iniciarExecucaoSecaoBalanco();
  },
  watch: {
    'leitorCodigoBarras.retorno': function (result) {
      if (this.leitorCodigoBarras.ativo) {
        this.leitorCodigoBarras.ativo = false;
      }

      if (result && result.codigoBarras) {
        this.produto.CodeBar = result.codigoBarras;
        this.buscarItem(result.codigoBarras, true);
      }
    },
  },

  methods: {
    biparLocalizacao(localizacao) {
      if (!localizacao.length) {
        this.limparCampos();
        this._setarFocusLocalizacao();
      } else this._setarFocusProduto();
    },

    async buscarItem(codebar, ehLeituraCodigoBarras) {
      var self = this;
      self.leitorCodigoBarras.retorno = null;
      if (!codebar.length) return;

      if (this.requerLocalizacao) {
        swal.exibirMensagem('Favor informar a localização do produto');
        self._setarFocusLocalizacao();
        return;
      }
      if (codebar == '') {
        swal.exibirMensagem('Informe um código de barras');
        self._setarFocusProduto();
        return;
      }
      if (self.produto.Quantidade == 0) {
        swal.exibirMensagem('A quantidade deve ser maior que zero');
        return;
      } else {
        var produtoNovo = self.produtoPesquisado;
        // Chamar API para conferir item a cada bipagem
        let commandItens = {
          IdBalanco: self.balancoSelecionado.IdBalanco,
          IdSecao: self.secaoSelecionada.Id,
          Etapa: self.secaoSelecionada.Etapa,
          CodigoBarras: codebar,
          Quantidade: parseInt(self.produto.Quantidade),
          SiglaLocalizacao: self.siglaLocalizacao,
        };
        loading.exibir();
        await self.$store
          .dispatch('balanco/conferirExecucaoItemSecao', commandItens)
          .then((response) => {
            if (produtoNovo != null && produtoNovo != undefined) {
              produtoNovo.Quantidade = parseInt(response.Quantidade);
              produtoNovo.CodeBar = response.CodigoBarras;
              produtoNovo.Referencia = response.Referencia;
              produtoNovo.SiglaLocalizacao = response.SiglaLocalizacao;
              produtoNovo.Nome = response.Nome;
              produtoNovo.CodigoProduto = response.CodigoProduto;
              produtoNovo.IdProdutoLocalizacaoDeposito = response.IdProdutoLocalizacaoDeposito;

              var produtos = self.listaItensConferidos;
              var produtoExiste = produtos.find(function (p) {
                if (p.CodigoProduto == response.CodigoProduto && p.IdProdutoLocalizacaoDeposito == response.IdProdutoLocalizacaoDeposito) {
                  p.Quantidade = parseInt(response.Quantidade);
                  return true;
                }
              });

              if (!produtoExiste) {
                produtos.unshift(produtoNovo);
              }
              self.$store.dispatch('balanco/atualizarListaItensConferidos', produtos);
              self.limparCampos();

              //caso venha do leitor de código de barras, abre novamente para bipar
              if (ehLeituraCodigoBarras) self.abrirLeitorCodigoBarras();
            }
          })
          .catch((erro) => {
            self._setarFocusProduto();
            this.$helper.globalErrorHandler(erro, 'Não foi possível obter os dados necessários, tente novamente');
          })
          .finally(function () {
            loading.ocultar();
          });
      }
    },

    onExcluirProduto(produto) {
      swal.exibirPergunta('Excluir Item', `Deseja excluir o item:<br> ${produto.Nome}<br> da lista de conferência?`, async (response) => {
        if (response) {
          this.excluirProduto(produto);
        }
      });
    },

    excluirProduto(produto) {
      var self = this;
      let command = {
        IdBalanco: self.balancoSelecionado.IdBalanco,
        IdSecao: self.secaoSelecionada.Id,
        Etapa: self.secaoSelecionada.Etapa,
        CodigoProduto: produto.CodigoProduto,
        IdProdutoLocalizacaoDeposito: produto.IdProdutoLocalizacaoDeposito,
      };
      loading.exibir();
      self.$store
        .dispatch('balanco/excluirConferenciaExecucaoItemSecao', command)
        .then(() => {
          let produtos = self.listaItensConferidos.filter((item) => {
            return !(item.CodigoProduto == produto.CodigoProduto && item.IdProdutoLocalizacaoDeposito == produto.IdProdutoLocalizacaoDeposito);
          });
          self.$store.dispatch('balanco/atualizarListaItensConferidos', produtos);
          swal.exibirToastSucesso('Produto excluído!');
        })
        .catch((erro) => {
          if (erro.response?.status == 400) {
            swal.exibirMensagemErro(erro.response.data);
          } else {
            swal.exibirMensagemErro('Ocorreu um problema ao excluir o produto.');
          }
          return Promise.reject(erro);
        })
        .finally(function () {
          loading.ocultar();
        });
    },

    finalizarExecucaoSecao(confirmaDivergentes) {
      var self = this;
      confirmaDivergentes = confirmaDivergentes || false;
      loading.exibir();
      const command = {
        IdBalanco: self.balancoSelecionado.IdBalanco,
        IdSecao: self.secaoSelecionada.Id,
        Etapa: self.secaoSelecionada.Etapa,
        ValidarDivergentes: self.etapa === '3' && !confirmaDivergentes,
      };
      self.$store
        .dispatch('balanco/finalizarExecucaoSecao', command)
        .then(() => {
          swal.exibirMensagemSucesso('Seção finalizada com sucesso', () => {
            this.redirecionarPagina();
          });
          return Promise.resolve();
        })
        .catch(function (erro) {
          if (erro.response?.status == 400) {
            if (command.ValidarDivergentes && erro.response.data.length == 1 && erro.response.data[0].startsWith('Existem itens')) {
              swal.exibirMensagemComPergunta('Confirmação', erro.response.data + '<br>Confirma?', (sim) => {
                if (sim) self.finalizarExecucaoSecao(true);
              });
            } else {
              swal.exibirMensagemErro(erro.response.data);
            }
          } else {
            swal.exibirMensagemErro('Ocorreu um problema ao finalizar a execução do balanço.');
          }
          return Promise.reject(erro);
        })
        .finally(function () {
          loading.ocultar();
        });
    },

    async iniciarExecucaoSecaoBalanco() {
      var self = this;
      if (!this.isLoading) {
        let command = {
          IdBalanco: self.balancoSelecionado.IdBalanco,
          IdSecao: self.secaoSelecionada.Id,
          Etapa: self.secaoSelecionada.Etapa,
        };

        loading.exibir();
        await self.$store
          .dispatch('balanco/iniciarExecucaoSecaoBalanco', command)
          .then((response) => {
            this.dadosExecucaoSecaoBalanco = response;
            self.$store.dispatch('balanco/atualizarListaItensConferidos', response.Itens);

            this.telaPronta = true;
          })
          .catch((err) => {
            this.$helper.globalErrorHandler(err, 'Não foi possível obter os dados necessários para iniciar o balanço, tente novamente');
            self.redirecionarPagina();
          })
          .finally(function () {
            loading.ocultar();
          });

        if (this.dadosExecucaoSecaoBalanco.ControlaLocalizacao) this._setarFocusLocalizacao();
        else this._setarFocusProduto();
      } else {
        swal.exibirMensagemErro('Ocorreu um problema ao iniciar a execução do balanço.');
      }
    },

    redirecionarPagina() {
      this.$router.go(-1);
    },

    limparCampos() {
      this.produto.CodeBar = '';
      this.produto.Quantidade = 1;
      this.$refs.inputPesquisaProduto.limparCriterio();
    },

    abrirLeitorCodigoBarras() {
      this.leitorCodigoBarras.ativo = true;
    },

    obterRangeLocalizacao(secao) {
      return `${secao.LocalizacaoInicial} à ${secao.LocalizacaoFinal}`;
    },

    _setarFocusLocalizacao() {
      document.getElementById('inputPesquisaLocalizacao').focus();
    },

    _setarFocusProduto() {
      document.getElementById('inputPesquisaProduto').focus();
    },
  },
};
</script>

<style scoped>
.border-primary {
  border-width: 2px !important;
}
.tamanho-card-mobile {
  margin-top: 0px;
  margin-bottom: 3rem !important;
}
.card-header-filter {
  /* margin-top: 58px; */
  margin-top: 0px;
  background-color: #f9f9f9;
}

.tamanho-card {
  height: 100%;
}
.btn-clear {
  color: #ee9ca4;
  background-color: #fff;
  border-color: #f3828e;
}
</style>
